import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
// import Masonry from "react-masonry-component";
import SEO from "../components/seo";
import ImagesCard from "../components/slices/ImagesCard";
import TexteCard from "../components/slices/TexteCard";
import VideoCard from "../components/slices/VideoCard";
// import CardTest from "../components/slices/CardTest";

export const query = graphql`
  query Home {
    prismicHome {
      type
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        title {
          text
        }
        body {
          ... on PrismicHomeBodyImageCard {
            slice_type
            primary {
              width
              padding
              translation
              align
              fig
              image {
                ...sharp
              }
              project {
                ...projectCard
              }
            }
          }
          ... on PrismicHomeBodyTexteCard {
            slice_type
            primary {
              width
              padding
              texte {
                raw
              }
              project {
                ...projectCard
              }
            }
          }
          ... on PrismicHomeBodyVideoCard {
            slice_type
            primary {
              width
              padding
              fig
              video {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const HomeTemplate = ({ path, pageContext, data }) => {
  const { template } = pageContext;
  const { meta_title, meta_description, meta_image, title, body } =
    data.prismicHome.data;
  // console.log(body);
  useEffect(() => {
    const token = PubSub.subscribe("SCROLL_BOTTOM", (e, d) => {
      console.log("home isBottom");
      PubSub.publish("MENU_CATEGORY_OPEN");
    });
    // window.addEventListener("scroll", _scroll);

    return () => {
      // window.removeEventListener("scroll", _scroll);
      PubSub.unsubscribe(token);
    };
  }, []);

  // const _scroll = () => {
  //   const { clientHeight, scrollHeight, scrollY: scrollTop } = document.body;
  //   // console.log(clientHeight + scrollY, scrollHeight);
  //   if (clientHeight + scrollY >= scrollHeight) {
  //     PubSub.publish("MENU_CATEGORY_TOGGLE", true);
  //   }
  // };

  const slices = body.map((slice, i) => {
    // console.log(slice.slice_type);
    switch (slice.slice_type) {
      case "image_card":
        return <ImagesCard key={i} input={slice} />;
      case "texte_card":
        return <TexteCard key={i} input={slice} />;
      case "video_card":
        return <VideoCard key={i} input={slice} />;
      default:
        return null;
    }
  });

  return (
    <div className='page-template page-home'>
      <SEO
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageImage={meta_image.url}
        template={template}
        page={false}
        pathname={path}
        documentClass=''
      />

      <div className='pt-headerHeight--'>
        {/* <Masonry className={"projets-grid"}>{slices}</Masonry> */}
        <div className='home-grid flex flex-wrap justify-center- items-baseline- px-sm md:px-md'>
          {slices}
        </div>
      </div>
      <div className='h-screen'></div>
    </div>
  );
};

export default withPreview(HomeTemplate);
