import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";
import clsx from "clsx";
import FigureWithGradientPlaceholder from "../ui/FigureWithGradientPlaceholder";
import { _getPaddingCss } from "../../core/utils";

const ImagesCard = ({ input }) => {
  const { width, padding, translation, align, image, project, fig } =
    input.primary;
  // console.log(align);

  const _getMainCategory = () => {
    return project.document?.data.categories.filter(
      (el) => el.category_principale
    );
  };

  const mainCategory = _getMainCategory();
  const hasLink = mainCategory?.length > 0;

  // console.log(project.document.uid, hasLink);
  const _getCategoryUrlWithAnchor = () => {
    if (mainCategory.length === 0) {
      // console.log(project.document.data.categories);
      return `/`;
    } else {
      return `/category/${mainCategory[0].category.document.uid}/#${project.document.uid}`;
    }
  };

  const _getAlign = () => {
    switch (align) {
      case "top":
        return "self-start";
      case "center":
        return "self-center";
      case "bottom":
        return "self-end";
      default:
        return "self-top";
    }
  };

  const _getTranslation = () => {
    if (!translation) return "translate(0,0)";
    else {
      const parts = translation.split(",");
      return `translate(${parts[0]}vw, ${parts[1]}vh)`;
    }
  };

  // const hasLink = project.document !== null;
  const sectionPadding = padding ? _getPaddingCss(padding, "vw") : "0";

  return (
    <article
      className={_getAlign()}
      style={{
        width: `${width}%`,
        transform: _getTranslation(),
      }}>
      {hasLink ? (
        <Link to={_getCategoryUrlWithAnchor()} title={image.alt}>
          <div className='inner' style={{ padding: sectionPadding }}>
            <figure>
              {/* <Img {...image} /> */}
              <FigureWithGradientPlaceholder input={image} />
              {fig && (
                <figcaption
                  className={clsx("text-sm italic text-secondary py-xs")}>
                  fig <span>{fig}</span>
                </figcaption>
              )}
            </figure>
          </div>
        </Link>
      ) : (
        <div className='inner bg-gray-' style={{ padding: sectionPadding }}>
          <figure>
            {/* <Img {...image} /> */}
            <FigureWithGradientPlaceholder input={image} />

            {fig && (
              <figcaption
                className={clsx("text-sm italic text-secondary py-xs")}>
                fig <span>{fig}</span>
              </figcaption>
            )}
          </figure>
        </div>
      )}
    </article>
  );
};

export default ImagesCard;
