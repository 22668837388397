import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import { _getPaddingCss } from "../../core/utils";

const TexteCard = ({ input }) => {
  const { width, padding, texte, project } = input.primary;

  const _getMainCategory = () => {
    return project.document.data.categories.filter(
      (el) => el.category_principale
    );
  };

  // const mainCategory = _getMainCategory();
  // const hasLink = mainCategory.length > 0;

  // console.log(hasLink, mainCategory);
  const _getCategoryUrlWithAnchor = () => {
    if (mainCategory.length === 0) {
      // console.log(project.document.data.categories);
      return `/`;
    } else {
      return `/category/${mainCategory[0].category.document.uid}/#${project.document.uid}`;
    }
  };

  // console.log(project.document.data.categories);
  // console.log(_getCategoryUrlWithAnchor());

  const sectionPadding = padding ? _getPaddingCss(padding, "vw") : "0";

  return (
    <article
      className='self-start'
      style={{
        width: `${width}px`,
      }}>
      <div className='inner bg-gray-' style={{ padding: sectionPadding }}>
        {/* {hasLink ? (
          <Link
            to={_getCategoryUrlWithAnchor()}
            title={project.document.data.title.text}>
            <RichText
              render={texte.raw}
              // htmlSerializer={htmlSerializer}
            />
          </Link>
        ) : (
          <RichText
            render={texte.raw}
            // htmlSerializer={htmlSerializer}
          />
        )} */}
        <RichText
          render={texte.raw}
          // htmlSerializer={htmlSerializer}
        />
      </div>
    </article>
  );
};

export default TexteCard;
