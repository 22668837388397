import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { _getPaddingCss } from "../../core/utils";

const VideoCard = ({ input }) => {
  const { width, padding, video } = input.primary;
  const [play, setPlay] = useState(false);

  const videoRef = useRef();
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [inView]);

  const sectionPadding = padding ? _getPaddingCss(padding, "%") : "0";

  return (
    <article
      style={{
        width: `${width}%`,
      }}
      ref={ref}>
      <div className='inner bg-gray-' style={{ padding: sectionPadding }}>
        <video muted loop ref={videoRef}>
          <source src={video.url} type='video/mp4'></source>
        </video>
        {/* {play && <div>play</div>} */}
      </div>
    </article>
  );
};

export default VideoCard;
